import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import './TabComponent.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image1 from '../Assets/Home/Industries/Industrys-Icons-01.png';
import image2 from '../Assets/Home/Industries/Industrys-Icons-02.png';
import image3 from '../Assets/Home/Industries/Industrys-Icons-03.png';
import image4 from '../Assets/Home/Industries/Industrys-Icons-04.png';
import image5 from '../Assets/Home/Industries/Industrys-Icons-05.png';
import image6 from '../Assets/Home/Industries/Industrys-Icons-06.png';
import image7 from '../Assets/Home/Industries/Industrys-Icons-07.png';
import image8 from '../Assets/Home/Industries/Industrys-Icons-08.png';
import image9 from '../Assets/Home/Industries/Industrys-Icons-09.png';
import { Col, Row } from 'react-bootstrap';
import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton';


const TabComponent = () => {
  const [activeTab, setActiveTab] = useState('oil&gas');
  const tabCycleRef = useRef(null);

  const tabs = [
    { id: 'oil&gas', label: 'Oil & Gas', imgSrc: image1 },
    { id: 'manufacturing', label: 'Manufacturing', imgSrc: image2 },
    { id: 'cement', label: 'Cement', imgSrc: image3 },
    { id: 'chemical', label: 'Chemicals & Refineries', imgSrc: image4 },
    { id: 'energy', label: 'Energy & Utilities', imgSrc: image5 },
    { id: 'iron&steel', label: 'Iron & Steel', imgSrc: image6 },
    { id: 'airport', label: 'Airport & Aviation', imgSrc: image7 },
    { id: 'education', label: 'Education', imgSrc: image8 },
    { id: 'food', label: 'Food and Beverages', imgSrc: image9 },

  ];

  const tabChange = () => {
    const currentIndex = tabs.findIndex(tab => tab.id === activeTab);
    const nextIndex = (currentIndex + 1) % tabs.length;
    setActiveTab(tabs[nextIndex].id);
  };

  useEffect(() => {
    tabCycleRef.current = setInterval(tabChange, 5000);
    return () => clearInterval(tabCycleRef.current);
  }, [activeTab]);

  const handleTabClick = (tabId) => {
    clearInterval(tabCycleRef.current);
    setActiveTab(tabId);
    tabCycleRef.current = setInterval(tabChange, 5000);
  };

  const handleMouseEnter = () => {
    clearInterval(tabCycleRef.current);
  };

  const handleMouseLeave = () => {
    tabCycleRef.current = setInterval(tabChange, 5000);
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5, // Adjust based on the number of tabs you want to show
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 7000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="cycle-tab-container" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Slider {...settings} className="nav nav-tabs">
        {tabs.map((tab) => (
          <div key={tab.id} className={`cycle-tab-item ${activeTab === tab.id ? 'active' : ''}`}>
            <a
              className="nav-link"
              role="tab"
              onClick={() => handleTabClick(tab.id)}

            >
              <div className="tab-icon">
                <img src={tab.imgSrc} alt={tab.label} loading='lazy'/>
                {tab.label}
              </div>

            </a>
          </div>
        ))}
      </Slider>
      <div className="tab-content p-3">
        <div className={`tab-pane fade ${activeTab === 'oil&gas' ? 'active in' : ''}`} id="oil&gas" role="tabpanel" aria-labelledby="oil&gas-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Oil-and-Gas.png")} alt="Optimized Supply Chain" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Optimized Supply Chain of Petrochemical Conglomerate</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>

                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />

                </div>


              </div>
            </Col>
          </Row>
        </div>
        <div className={`tab-pane fade ${activeTab === 'manufacturing' ? 'active in' : ''}`} id="manufacturing" role="tabpanel" aria-labelledby="manufacturing-tab">

          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Manufacture.png")} alt="Manifacture" loading='lazy'/>

              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Manufacture</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>

                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />

                </div>


              </div>
            </Col>
          </Row>

        </div>
        <div className={`tab-pane fade ${activeTab === 'cement' ? 'active in' : ''}`} id="cement" role="tabpanel" aria-labelledby="cement-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Cement.png")} alt="Cement" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Cement</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`tab-pane fade ${activeTab === 'chemical' ? 'active in' : ''}`} id="chemical" role="tabpanel" aria-labelledby="chemical-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Chemicals-and-Refineries.png")} alt="Chemicals-and-Refineries" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Chemicals-and-Refineries</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`tab-pane fade ${activeTab === 'energy' ? 'active in' : ''}`} id="energy" role="tabpanel" aria-labelledby="energy-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Energy-and-Utilities.png")} alt="Energy-and-Utilities" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Energy-and-Utilities</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`tab-pane fade ${activeTab === 'iron&steel' ? 'active in' : ''}`} id="iron&steel" role="tabpanel" aria-labelledby="iron&steel-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Iron-and-Steel.png")} alt="Iron-and-Steel" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Iron-and-Steel</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className={`tab-pane fade ${activeTab === 'airport' ? 'active in' : ''}`} id="airport" role="tabpanel" aria-labelledby="airport-tab">
          <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Airport-and-Aviation.png")} alt="Airport-and-Aviation" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2 className='text-start'>Enhancing Operational Efficiency & Delivering Exceptional CX</h2>
                <p>Implementing cutting-edge master data management technologies to optimize airport operations, resulting in operational cost reductions and improved passenger experiences. </p>
                <span><b>Curious about our innovative solutions for airport facility management?</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div><div className={`tab-pane fade ${activeTab === 'education' ? 'active in' : ''}`} id="education" role="tabpanel" aria-labelledby="education-tab">
        <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Education.png")} alt="Education" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Education</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div><div className={`tab-pane fade ${activeTab === 'food' ? 'active in' : ''}`} id="food  " role="tabpanel" aria-labelledby="food   -tab">
        <Row>
            <Col lg={5}>
              <div>
                <img className='w-100' src={require("../Assets/Home/Food and Beverages.png")} alt="Food and Beverages" loading='lazy'/>
              </div>
            </Col>
            <Col lg={7}>
              <div className='optimize-content'>
                <h2>Food and Beverages</h2>
                <p>Implementing best-in-class ideas towards operational excellence with more than 15% reduction in pricing year on year</p>
                <span><b>Would you like to know more about Inventory Management Solutions</b></span>
                <div className='d-flex text-left mt-5'>
                  <ReadMoreButton name="Read Now" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default TabComponent;
