import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from 'react-bootstrap';

function createData(name, content, subheading, history) {
    return {
        name,
        content,
        subheading,
        history
    };
}

const rows = [
    createData('Subscription and Licensing', "", "Details", [
        { subheading: 'Type of Subscription', icon1: 'Montly', icon2:  'Montly', icon3:  'Montly'},
        { subheading: 'Licensing Model', icon1: "Seat Based", icon2: "Seat Based", icon3: "Seat Based", icon4: "Seat Based" },
        
    ]),
    createData('User management', "", "Details", [
        { subheading: 'Number of Users', icon1:"1", icon2: "2", icon3: "3" },
        

    ]),
    createData('Data Integration', "", "Details", [
        { subheading: 'Files', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Database Connections', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'ERP Connectivity', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />, icon4: <CheckIcon /> },
        { subheading: 'Online Services',     icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Import from Social Media Sites', icon2: <CheckIcon />, icon3: <CheckIcon /> },
        

    ]),
    createData('Data Analysis and Visualization', "", "Details", [
        { subheading: 'Data Articulation and Visualization', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Data retrieval and modelling through SQL/Python', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Chart Creation', icon1: "40", icon2: "80", icon3: "Unlimited" },
        { subheading: 'Dashboards Creation', icon1: "5", icon2: "10", icon3: "Unlimted  " },
        { subheading: 'Advanced Data Preparation', icon1:<CheckIcon />, icon2:  <CheckIcon />, icon3: <CheckIcon />},
        { subheading: "Auto Suggestion Charts", icon1:"", icon2:<CheckIcon />, icon3:<CheckIcon />},
        { subheading: "Data Querying", icon1: <CheckIcon /> , icon2: <CheckIcon /> , icon3: <CheckIcon /> },
        { subheading: "Refresh Rate", icon1: "Daily", icon2: "Hourly", icon3:"Hourly"},
        { subheading: "Predictive Analytics", icon2: <CheckIcon /> , icon3: <CheckIcon /> },
        { subheading: "Personalized Dashboards", icon1: <CheckIcon />, icon2:<CheckIcon />, icon3: <CheckIcon />}

    ]),
    createData('Co-pilot', "", "Details", [
        { subheading: 'AI-powered search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Get AI-powered answers to search queries', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Create dashboards and charts', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
    ]),
    createData('Visualization features', "", "Details", [
        { subheading: 'Save charts & dashboards', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Drill down anywhere in any chart without predefined drill paths', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Apply filters across an entire dashboard', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />, icon4: <CheckIcon /> },
        { subheading: 'Lay out your dashboard with unlimited flexibility', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Download to PDF', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Download to CSV, XLSX', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Download as image', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Share Dashboards', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},

    ]),
    createData('Auto-analyze', "", "Details", [
        { subheading: 'Visualize anomalies', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Analyze trends automatically', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Conduct correlation analysis', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Identify key drivers of metric changes', icon2: <CheckIcon />, icon3: <CheckIcon /> },
       

    ]),
    createData('Dynamic dashboard & Live Reporting', "", "Details", [
        { subheading: 'Dashboard level controls', icon1:<CheckIcon /> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
        { subheading: 'Card level controls', icon1: <CheckIcon /> , icon2: <CheckIcon /> , icon3: <CheckIcon />  },
        { subheading: 'Chart level controls', icon1: <CheckIcon /> , icon2: <CheckIcon /> , icon3: <CheckIcon />  },
        

    ]),
    createData('Governance and Security', "", "Details", [
        { subheading: 'Governance', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Data Security & Encryption', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Export and Publish', icon1:<CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        {subheading: 'Maximum Storage', icon1:'1GB', icon2:'5GB',icon3:'5GB'},

    ]),
    createData('Support', "", "Details", [
        { subheading: 'Email and phone support', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Support hours', icon1: '24*7', icon2: '24*7', icon3: '24*7' },
        { subheading: 'Support portal access', icon3: <CheckIcon /> },
        { subheading: 'In-app support', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />  },
        

    ]),
    

    // Add other rows as needed
];

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <TableRow className='table-body'>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <h5>{row.name}</h5>
                    <p>{row.content}</p>
                </TableCell>
              
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} >
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Box sx={{ margin: 1 }} className='border-bottom border-dark'>
                            <Typography variant="h6" gutterBottom component="div" >

                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody >
                                    {row.history && row.history.map((historyRow, index) => (
                                        <TableRow key={index}>
                                            <TableCell className='sub-head border-bottom border-gray'>{historyRow.subheading}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon1}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon2}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon3}</TableCell>
                                          

                                         
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                subheading: PropTypes.string.isRequired,
                icon: PropTypes.element.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export const SubscriptionTable = () => {
  return (
    <TableContainer component={Paper}>
    <Table aria-label="collapsible table">
        <TableHead className='sticky-header' >
            <TableRow className='table-row'>
                <TableCell />
                <th className="head-1 text-start px-3"><p>Check out our feature highlights that turn data into decisions!</p></th>

                {/* <th className="head-2 shadow rounded m-3">
                    <h4>Team</h4>
                    <p>$95 / mo</p>
                    <Button className="px-4">Try Free</Button>
                </th> */}
                <th className="head-2 shadow p-3 mb-5  rounded"><h4>Essentials</h4>
                    <p>$1250 / mo</p>
                    <Button className="px-4">Try Free</Button></th>
                <th className="head-2 py-2 shadow rounded-3"><h4>Pro</h4>
                    <p>Custom</p>
                    <Button className="px-4">Try Free</Button></th>
                <th className="head-2 py-2 shadow rounded-3"><h4>Enterprise</h4>
                    <p>Custom</p>
                    <Button className="px-4">Try Free</Button></th>
            </TableRow>
        </TableHead>
        <TableBody>
            {rows.map((row) => (
                <Row key={row.name} row={row} />
            ))}
        </TableBody>
    </Table>
</TableContainer>
  )
}
