import React from 'react'
import { InlineWidget } from 'react-calendly'

export const CalendlyComponent = () => {
  return (
    <div>
        <br/>
        <br/>
        <InlineWidget url="https://calendly.com/soujanya-moola-piloggroup"/>
    </div>
  )
}
