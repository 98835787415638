import React, { useState } from 'react'
import './ContactForm.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';


export const ContactForm = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
      });
    
      const handleFocus = (e) => {
        e.target.parentNode.classList.add('focus');
      };
    
      const handleBlur = (e) => {
        if (e.target.value === '') {
          e.target.parentNode.classList.remove('focus');
        }
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };

  return (
    <div className="contact-form-container">
      <span className="big-circle"></span>
      <img src="img/shape.png" className="square" alt="" loading='lazy'/>
      <div className="form">
        <div className="contact-info">
          <h3 className="title">Let's get in touch</h3>
          <p className="text">
          We would be delighted to serve you with our services, just use the form below or choose the services you are interested in and we will be in touch with you.
          </p>

          <div className="info">
            <div className="information">
              <FontAwesomeIcon icon={faMapMarkerAlt} /> &nbsp;&nbsp;
              <p>3rd Floor, Unit-1, MJR Magnifique, Nanakramguda X road, Near Wells Fargo, Raidurg Navakhalsa, Hyderabad, Telangana - 500 008</p>
            </div>
            <div className="information">
              <FontAwesomeIcon icon={faEnvelope} /> &nbsp;&nbsp;
              <p>info@piloggroup.com</p>
            </div>
            <div className="information">
              <FontAwesomeIcon icon={faPhone} />&nbsp;&nbsp;
              <p>+91 40 660 17 777/788</p>
            </div>
          </div>

          <div className="social-media">
            <p>Connect with us :</p>
            <div className="social-icons">
              <a href="#">
                <FontAwesomeIcon icon={faFacebookF} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faTwitter} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faInstagram} />
              </a>
              <a href="#">
                <FontAwesomeIcon icon={faLinkedinIn} />
              </a>
            </div>
          </div>
        </div>

        <div className="contact-form">
          <span className="circle one"></span>
          <span className="circle two"></span>

          <form action="index.html" autoComplete="off">
            <h3 className="title">Contact us</h3>
            <div className="input-container">
              <input
                type="text"
                name="name"
                className="input"
                value={formData.name}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label>Username</label>
              <span>Username</span>
            </div>
            <div className="input-container">
              <input
                type="email"
                name="email"
                className="input"
                value={formData.email}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label>Email</label>
              <span>Email</span>
            </div>
            <div className="input-container">
              <select name="" id="">
<option value="one">one</option>
<option value="one">two</option>
<option value="one">three</option>
<option value="one">four</option>
              </select>
              
              <label>Country Code</label>
              <span>Country Code</span>
            </div>
            <div className="input-container">
              <input
                type="tel"
                name="phone"
                className="input"
                value={formData.phone}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              />
              <label>Phone</label>
              <span>Phone</span>
            </div>
            <div className="input-container textarea">
              <textarea
                name="message"
                className="input"
                value={formData.message}
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={handleBlur}
              ></textarea>
              <label>Message</label>
              <span>Message</span>
            </div>
            <input type="submit"  className="btn" />
          </form>
        </div>
      </div>
    </div>
  
  )
}
