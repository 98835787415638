import React from 'react'

import './StrategicAdvisory.css'
import { Col, Container, Row } from 'react-bootstrap'

export const StrategicAdvisory = () => {
    return (
        <div>
            <section className='Strategic-Advisory-Section1'>
                <Container>
                    <Row>
                        <Col>
                            <div className='Strategic-Advisory-Section1-content'>
                                <h1>Strategic Advisory</h1>
                                <p>A value proposition highlights the unique benefits a product or service offers, <br />
                                    explaining why it’s the best choice for addressing customer needs.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
            <section>
                <Container>

                    <Row className='justify-content-center align-items-center m-3'>

                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                                <h2>Data maturity Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-01.png")} alt="AI Advisory-Icons-01" loading='lazy'/>
                                    <p>Data As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-02.png")} alt="AI Advisory-Icons-02" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>

                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-04.png")} alt="AI Advisory-Icons-04" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-05.png")} alt="AI Advisory-Icons-05" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12}  >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/Data maturity Assessment.png")} alt="ldg-key-aspects" loading='lazy' />

                            </div>
                        </Col>
                    </Row>



                    <Row className='justify-content-center align-items-center m-3 mt-5 pt-5'>
                        <Col lg={7} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/Process Maturity Assessment.png")} alt="ldg-key-aspects" loading='lazy'/>

                            </div>
                        </Col>
                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                                <h2>Process Maturity Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-07.png")} alt="AI Advisory-Icons-07" loading='lazy'/>
                                    <p>Data As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-08.png")} alt="AI Advisory-Icons-08" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-09.png")} alt="AI Advisory-Icons-09" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-09.png")} alt="AI Advisory-Icons-09" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className='justify-content-center align-items-center m-3  mt-5 pt-5'>

                        <Col lg={4} md={6} sm={12} xs={12} >
                            <div className='Strategic-Advisory-Section2-content'>
                                <h2>AI Readiness Assessment</h2>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-01.png")} alt="AI Advisory-Icons-01.png" loading='lazy'/>
                                    <p>As Is Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-02.png")} alt="AI Advisory-Icons-02.png" loading='lazy'/>
                                    <p>Data Analysis</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-03.png")} alt="AI Advisory-Icons-03.png" loading='lazy'/>
                                    <p>Data Maturity Assessment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-04.png")} alt="AI Advisory-Icons-04" loading='lazy'/>
                                    <p>Data Enrichment</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-05.png")} alt="AI Advisory-Icons-05" loading='lazy'/>
                                    <p>Road Map</p>
                                </div>
                                <div className='d-flex gap-3 align-items-center'>
                                    <img style={{ width: '40px' }} src={require("../Assets/Strategic-Advisory/AI Advisory-Icons-06.png")} alt="AI Advisory-Icons-06" loading='lazy'/>
                                    <p>Recommended Architecture</p>
                                </div>
                            </div>
                        </Col>
                        <Col lg={7} md={6} sm={12} xs={12}  >
                            <div className='Strategic-Advisory-Section2-image'>
                                <img className='w-100' src={require("../Assets/Strategic-Advisory/AI Advisory-compressed.jpg")} alt="AI Advisory-compressed" loading='lazy'/>

                            </div>
                        </Col>
                    </Row>

                </Container>
            </section>
        </div>
    )
}
