import React from 'react'
import './DataQalityManagement.css'
import { Carousel, Col, Container, Row, Tab, Tabs } from 'react-bootstrap'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export const DataQalityManagement = () => {
    return (
        <div>
            <section className='iDQM-Section1'>
                <h1>Intelligent Data Quality Management</h1>
                <p>Preconfigured templates, Auto Assignment of Class & Characteristics, <br />

                    ISO 8000 and UNSPSC compliant</p>
            </section>

            {/* <section className='iDQM-Section2'>
                <Container>
                    <Row>
                        <Col>
                            <div className='iDQM-Section2-items'>
                                <h6>PiLog Preferred Records (PPR)</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className='iDQM-Section2-items'>
                                <h6>PiLog Preferred Ontology (PPO)</h6>
                            </div>
                        </Col>
                        <Col>
                            <div className='iDQM-Section2-items'>
                                <h6>PiLog Preferred Hierarchy (PPH)</h6>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className='iDQM-Section3'>
                <Container>
                    <div className='iDQM-Section3-heading'>
                        <h2>About Data Quality Management</h2>
                        <p>Data Quality Management is aimed to automate the process of standardization, cleansing & management of unstructured / free text data by utilizing ASA

                            (Auto Structured Algorithms) built on PiLogs taxonomy and the catalog repositories of master data records.</p>
                    </div>
                    <Row className='justify-content-center align-items-center'>
                        <Col>
                            <div className='iDQM-Section3-items'>
                                <h4 className='text-start'>Data Quality Management Capabilities includes

                                    but not limited to</h4>

                                <Row className='my-1 p-3'>
                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-01.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Analyze the source data content for completeness, consistency,
                                                redundancy, standardization, richness, etc</p>
                                        </div>
                                    </Col>

                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-02.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Auto Assignment of Class & Characteristics from the PiLog's Taxonomy to each record</p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-03.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Extract the characteristic values & UOM's from the source descriptions for each record</p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-04.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Extract reference data from the source descriptions such as Part#/Model#/Drawing#/Mnfr/Vendor etc for each record</p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-05.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Bulk review of materials (QC Tools & DQ Assessment)</p>
                                        </div>
                                    </Col>
                                    <Col lg={12} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' style={{ width: '60px' }} src={require("../Assets/iDQM/iDQM-image-06.png")} alt="ldg-key-aspects" loading='lazy' />
                                            <p>Auto mapping of source data with PiLog repositories & other reliable sources</p>
                                        </div>
                                    </Col>

                                </Row>

                                {/* <ul className='text-start'>
                                    <li>Analyze the source data content for completeness, consistency,
                                        redundancy, standardization, richness, et</li>
                                    <li>Auto Assignment of Class & Characteristics from the PiLog's Taxonomy to each record</li>
                                    <li>Extract the characteristic values & UOM's from the source descriptions for each record</li>
                                    <li>Extract reference data from the source descriptions such as Part#/Model#/Drawing#/Mnfr/Vendor etc for each record</li>
                                    <li>Bulk review of materials (QC Tools & DQ Assessment)</li>
                                    <li>Auto mapping of source data with PiLog repositories & other reliable sources</li>
                                    <li>Assign the data sets to relevant user groups based on various criteria</li>
                                    <li>Capture additional information (or) Validate processed/structured data</li>
                                    <li>Provision to collect the field data & update (Physical Walk down)</li>
                                    <li>Auto-generation of Short & PO text based on User configured</li>
                                    <li> Identification of redundant records</li>
                                    <li> Export the data to be migrated to a Target system(s)</li>
                                    <li>Integrate in real-time with other systems</li>
                                    <li> Data Quality assessment & progress reports</li>
                                </ul> */}
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img className='w-100' src={require("../Assets/iDQM/About Data Quality Management.png")} alt="DQM Capabilities Include" loading='lazy' />

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <section className='p-5' style={{background:'#f5fcfb'}}>
                <Container>
                    <Row className='align-items-center'>
                        <Tabs
                            defaultActiveKey="PiLog Preferred Records (PPR)"
                            id="fill-tab-example"
                            className="mb-4 border"
                            fill
                        >
                            <Tab eventKey="PiLog Preferred Records (PPR)" title="PiLog Preferred Records (PPR)">
                                <div>
                                   
                                    <Row>
                                    <div className='iDQM-Section4-heading'>
                                            <h2>PiLog Preferred Records (PPR)</h2>
                                        </div>
                                        <Col lg={5}>
                                            <div className='iDQM-Section4-items'>
                                                <ul className='text-start'>
                                                    <li>PiLog objective is to have a global repository so that no
                                                        item requires manual cataloguing.</li>
                                                    <li>PiLog has developed PPR, A repository/ central location in
                                                        which data is stored and managed. It has embedded
                                                        industry-standard content and ISO compliant processes
                                                        (Data exchange compliant to ISO 29002)</li>
                                                    <li>PiLog has spent over twenty years in researching,
                                                        developing, and refining the PPR (PiLog Preferred Records)</li>
                                                    <li>For those wanting to start with structured descriptions now
                                                        without waiting for historical data clean up, we offer the
                                                        Structured Text Generator to build class-driven descriptions
                                                        that improve search capability and eliminate free-text
                                                        spending.</li>
                                                    <li>PPR is not only limited to materials but also has service
                                                        master domain details i.e., 0.5 million readily structured
                                                        service master records are available</li>
                                                    <li>PPR has 100% accuracy in the descriptions.</li>
                                                    <li>Re-usability of the golden records as they are independent
                                                        of "language", "region", "Industry Sector".</li>

                                                </ul>
                                            </div>
                                        </Col>
                                        <Col lg={7}>
                                        <img className='w-100' src={require("../Assets/iDQM/solutions-01.png")} alt='Spares Data Acquisition-1' loading='lazy'/>

                                            {/* <Carousel data-bs-theme="dark">
                                                <Carousel.Item interval={1000}>
                                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-2.png")} alt='Spares Data Acquisition-1' />


                                                </Carousel.Item>
                                                <Carousel.Item interval={500}>
                                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-3.png")} alt='Spares Data Acquisition-1' />

                                                </Carousel.Item>
                                                <Carousel.Item>
                                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-4.png")} alt='Spares Data Acquisition-1' />

                                                </Carousel.Item>
                                            </Carousel> */}
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey="PiLog Preferred Ontology (PPO)" title="PiLog Preferred Ontology (PPO)">
                                <div>
                                    <Row>
                                        <Col lg={7}>
                                            <div className='iDQM-Section6-heading'>
                                                <h2 className='text-start'>PiLog Preferred Ontology (PPO)</h2>
                                            </div>
                                            <div className='iDQM-Section6-items'>
                                                <p>PiLog throughout the 25 years of experience in master data solutions across different industries have
                                                    developed PiLog Preferred Ontology (PPO) which is a Technical Dictionary that complies with the ISO
                                                    8000 standard. The PPO is a best-defined industry specific dictionary covering all industry verticals
                                                    such as Petrochemical, Iron & Steel, Oil & Gas, Cement, Transport, Utilities, Retail etc.</p>
                                                <p>
                                                    PiLog s Taxonomy consists of pre-defined templates. Each template consists of a list of classes
                                                    (object qualifier or noun-modifier combination) with a set of predefined characteristics (properties /
                                                    attributes) per class. PiLog will make the PPO (class/characteristics/abbreviations) available for
                                                    general reference via the Data Harmonization Solution (DHS) and Master Data Ontology Manager
                                                    (MDOM) tools.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <img style={{ width: '100%' }} src={require("../Assets/iDQM/Spares Data Acquisition-07.png")} alt='Spares Data Acquisition-7' loading='lazy'/>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>
                            <Tab eventKey="PiLog Preferred Hierarchy (PPH)" title="PiLog Preferred Hierarchy (PPH)">
                            <div>
                                    <Row>
                                        <Col lg={7}>
                                            <div className='iDQM-Section6-heading'>
                                                <h2 className='text-start'>PiLog Preferred Hierarchy (PPH)</h2>
                                            </div>
                                            <div className='iDQM-Section6-items'>
                                                <p>PPH is the combination of business activities which are the categories and the universal or the standards that are classified by the standard bodies, so it is the combination of both.</p>
                                                <p>
                                                This is used to determine the business activities, economic activities, and any of the spend categories, spend analysis, sourcing of the information as well as classifying the information for local bodies as well as global bodies or standard bodies.
                                                </p>
                                                <p>
                                                In this case, PPH is the combination of categories, sub-categories which are the business activities that any organization performs be it for products or services aligned or mapped with the classifications such as UNSPSC and ISIC. It is also mapped with taxonomy for describing those activities.
                                                </p>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <img style={{ width: '100%' }} src={require("../Assets/iDQM/PPH.png")} alt='Spares Data Acquisition-7' loading='lazy'/>

                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Tab>

                        </Tabs>
                    </Row>
                </Container>

            </section>

            {/* <section className='iDQM-Section4'>
                <Container>
                    <Row>

                        <div className='iDQM-Section4-heading'>
                            <h2>Spares Data Acquisition</h2>
                        </div>


                    </Row>
                    <Row>
                        <Col lg={5}>
                            <div className='iDQM-Section4-items'>
                                <ul className='text-start'>
                                    <li>PiLog objective is to have a global repository so that no
                                        item requires manual cataloguing.</li>
                                    <li>PiLog has developed PPR, A repository/ central location in
                                        which data is stored and managed. It has embedded
                                        industry-standard content and ISO compliant processes
                                        (Data exchange compliant to ISO 29002)</li>
                                    <li>PiLog has spent over twenty years in researching,
                                        developing, and refining the PPR (PiLog Preferred Records)</li>
                                    <li>For those wanting to start with structured descriptions now
                                        without waiting for historical data clean up, we offer the
                                        Structured Text Generator to build class-driven descriptions
                                        that improve search capability and eliminate free-text
                                        spending.</li>
                                    <li>PPR is not only limited to materials but also has service
                                        master domain details i.e., 0.5 million readily structured
                                        service master records are available</li>
                                    <li>PPR has 100% accuracy in the descriptions.</li>
                                    <li>Re-usability of the golden records as they are independent
                                        of "language", "region", "Industry Sector".</li>

                                </ul>
                            </div>
                        </Col>
                        <Col lg={7}>
                            <Carousel data-bs-theme="dark">
                                <Carousel.Item interval={1000}>
                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-2.png")} alt='Spares Data Acquisition-1' />


                                </Carousel.Item>
                                <Carousel.Item interval={500}>
                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-3.png")} alt='Spares Data Acquisition-1' />

                                </Carousel.Item>
                                <Carousel.Item>
                                    <img className='w-100' src={require("../Assets/iDQM/Spares Data Acquisition-4.png")} alt='Spares Data Acquisition-1' />

                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </section> */}

            {/* <section className='iDQM-Section5'>
                <Container>
                    <Row>
                        <Col lg={7}>

                        <div className='iDQM-Section5-heading'>
                        <h2 className='text-center'>Data Quality Standards</h2>

                        </div>
                        
                        <img className='w-100' src={require("../Assets/iDQM/Data Cleansing-05.png")} alt='Spares Data Acquisition-1' />

                        </Col>
                        <Col lg={5}>
                        <div className='iDQM-Section5-items'>
                            <p>The International Organization for Standardization (ISO) approved a set of 
                                standards for data quality as it relates to the exchange of master data 
                                between organizations and systems. These are primarily defined in the ISO 
                                8000 110, -120, -130, -140 and the ISO 22745 10, 30 and 40 standards. 
                                Although these standards were originally inspired by the business of 
                                replacement parts cataloguing, the standards potentially have a much 
                                broader application. The ISO 8000 standards are high level requirements 
                                that do not prescribe any specific syntax or semantics. On the other hand, 
                                the ISO 22745 standards are for a specific implementation of the ISO 8000 
                                standards in extensible markup language (XML) and are aimed primarily at 
                                parts cataloguing and industrial suppliers.</p>
                                <p>
                                PiLog Data Harmonization processes & methodologies complies to ISO 8000 & 
                                ISO 22745 standards.
                                </p>
                                <p>
                                PiLog utilizes the PiLog Preferred Ontology (PPO) when structuring and 
                                cleansing Material, Asset/Equipment & Services Master records ensuring data 
                                deliverables comply with the ISO 8000 methodology, processes & standards 
                                for Syntax, Semantics, Accuracy, Provenance and Completeness.
                                </p>
                        </div>
                        </Col>

                    </Row>
                </Container>
            </section> */}

            {/* <section className='iDQM-Section6'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={7}>
                            <div className='iDQM-Section6-heading'>
                                <h2 className='text-start'>Taxonomy</h2>
                            </div>
                            <div className='iDQM-Section6-items'>
                                <p>PiLog throughout the 25 years of experience in master data solutions across different industries have
                                    developed PiLog Preferred Ontology (PPO) which is a Technical Dictionary that complies with the ISO
                                    8000 standard. The PPO is a best-defined industry specific dictionary covering all industry verticals
                                    such as Petrochemical, Iron & Steel, Oil & Gas, Cement, Transport, Utilities, Retail etc.</p>
                                <p>
                                    PiLog s Taxonomy consists of pre-defined templates. Each template consists of a list of classes
                                    (object qualifier or noun-modifier combination) with a set of predefined characteristics (properties /
                                    attributes) per class. PiLog will make the PPO (class/characteristics/abbreviations) available for
                                    general reference via the Data Harmonization Solution (DHS) and Master Data Ontology Manager
                                    (MDOM) tools.
                                </p>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img style={{ width: '100%' }} src={require("../Assets/iDQM/Spares Data Acquisition-07.png")} alt='Spares Data Acquisition-7' />

                            </div>
                        </Col>

                    </Row>
                    <Row>
                        <Col lg={7}>
                        <img style={{width:'100%'}} src={require("../Assets/iDQM/Spares Data Acquisition-06.png")} alt='Spares Data Acquisition-7' />

                        </Col>
                        <Col>
                        <h6>PiLog reserves the right to make these changes in the dictionary:</h6>
                        <div className='iDQM-Section6-items'>
                            <ul>
                                <li>Change existing classes and or characteristics of the CPO where 
                                necessary.</li>
                                <li>Register new classes and or characteristics in the OTD, and add 
                                them to the CPO.</li>
                                <li> If changes are made to the CPO dictionary, PiLog will only update 
                                the changes/additions in the MDPM software tool; no additional 
                                approval is required from Client to incorporate the changes, as 
                                PiLog manages the CPO dictionary according to industry standards.</li>
                                <li>The CPO dictionary is the intellectual property of PiLog. In no way 
                                may it be edited, copied, compared, mapped, transmitted, imported/
                                exported into other software/systems, or printed/published without 
                                prior written permission of PiLog. CPO includes concepts, classes, 
                                terms, definitions, languages, abbreviations, data requirements, 
                                equivalences, images, data types, translations, and any data 
                                structures or relationships of the content stored within the CPO.</li>
                            </ul>

                        </div>
                       
                        </Col>
                    </Row>
                </Container>
            </section> */}

            <section className='iDQM-Section7'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col lg={5}>
                            <div className='iDQM-Section7-heading'>
                                <h2>Data Cleansing</h2>
                                <div className='iDQM-Section7-items'>
                                    <p>The PiLog Master Data Project Management is used for cleansing
                                        and structuring of a material master is a highly specialized field,
                                        requiring the use of international standards, such as eOTD, USC,
                                        EAN, ISO 8000 etc.</p>
                                    <p>Effective cleansing and structuring of a material master
                                        consistent and correct application of these standards in large
                                        volumes of data requires specialized processes, methodologies
                                        and software tools.</p>
                                    <p>The material master forms the basis for a myriad of business
                                        objectives. PiLog understands the complex task of translating
                                        selected business objectives into master data requirements and
                                        subsequently designing a project that is focused on delivering
                                        optimal results in a cost effective way</p>
                                    
                                </div>
                            </div>

                        </Col>
                        <Col lg={7}>
                            <img style={{ width: '100%' }} src={require("../Assets/iDQM/Data Cleansing-09.png")} alt='Data Cleansing-09' loading='lazy' />

                        </Col>
                    </Row>
                </Container>
            </section>

            {/* <section className='iDQM-Section8'>
                <Container>
                    <div className='iDQM-Section8-heading'>
                        <h2>Cataloging Rules</h2>
                        <h5>PiLog follows three cataloging rules as follows:</h5>
                    </div>
                    <Row className='text-start'>
                       <div className='iDQM-Section8-items'>
                       
                       </div>
                       <Col lg={6}>
                        <div className='shadow rounded p-3 my-2 '>
                            <p> Do not remove or delete any data provided by the client unless the data is duplicated. Duplicated in this context refers to the scenario where a word, concept, 
                            value, attribute, etc. is duplicated within a single description or text provided for an item.</p>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className='shadow rounded p-3 my-2'>
                            <p>Records are never deleted by PiLog, but will be flagged as potential duplicates. It is the client s responsibility to verify and confirm whether items flagged as 
                            potential duplicates are indeed duplicates before removing them from the item master set.</p>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className='shadow rounded p-3 my-2'>
                            <p>Do not add extra values to client data unless researched from a source with integrity and authority. If PiLog adds values to a client s master data item, PiLog 
                            provides the source and authenticity for the added data.</p>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className='shadow rounded p-3 my-2'>
                            <p>linked, and the data for the material item is populated into the template. Descriptions are then generated according to certain rules. There are different Levels of 
                            cataloguing.</p>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className='shadow rounded p-3 my-2'>
                            <p>If descriptions are incomplete, incorrect, or contain conflicting information, query the client before assigning class or values. PiLog does not assign a class if the 
                            source description or information provided by the client is unclear. </p>
                        </div>
                        </Col>
                        <Col lg={6}>
                        <div className='shadow rounded p-3 my-2'>
                            <p>Electronic Data Verification (EDV) is the process whereby the source data received from the client is processed into the cataloguing system via the EOTD dictionary, 
                            where the correct item name and approved Attribute template is.</p>
                        </div>
                        </Col>
                        
                       
                    </Row>
                </Container>
            </section> */}

            <section className='iDQM-Section9'>
                <Container>
                    <div className='iDQM-Section9-heading'>
                        <h2>Data Quality Levels with samples:</h2>
                    </div>
                    <Carousel data-bs-theme="dark">
                        <Carousel.Item interval={1000}>
                            <img className='w-100' src={require("../Assets/iDQM/Data-Quality-Levels-with-samples1.png")} alt='Data-Quality-Levels-with-samples1' loading='lazy' />


                        </Carousel.Item>
                        <Carousel.Item interval={500}>
                            <img className='w-100' src={require("../Assets/iDQM/Data-Quality-Levels-with-samples2.png")} alt='Data-Quality-Levels-with-samples2' loading='lazy' />

                        </Carousel.Item>
                        <Carousel.Item>
                            <img className='w-100' src={require("../Assets/iDQM/Data-Quality-Levels-with-samples3.png")} alt='Data-Quality-Levels-with-samples3' loading='lazy' />

                        </Carousel.Item>
                    </Carousel>
                </Container>
            </section>

            <section>
                <Container>

                    <Row className='justify-content-center align-items-center'>
                        <Col lg={5}>
                            <div className='iDQM-Section10-heading'>
                                <h2>Add-Ons</h2>
                                <h6>Explore our brief videos showcasing the features of
                                    our unique add-ons of data quality management for
                                    your reference. Click here to watch.</h6>
                                <div className='iDQM-Section10-items'>
                                    <p> <ArrowRightIcon style={{ width: "3rem", height: '3rem' }} />Smart Consolidation</p>
                                    <p><ArrowRightIcon style={{ width: "3rem", height: '3rem' }} />RPA Robotic Process Automation BOTs</p>
                                    <p><ArrowRightIcon style={{ width: "3rem", height: '3rem' }} />Data Profiling and Analysis</p>
                                    <p><ArrowRightIcon style={{ width: "3rem", height: '3rem' }} />iSPIR</p>
                                </div>
                            </div>
                        </Col>
                        <Col>
                            <img className='w-100' src={require("../Assets/iDQM/Add-Ons.jpg")} alt='Add ons' loading='lazy' />

                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
