import logo from './logo.svg';
import './App.css';
import { Header } from './Components/Header/Header';
import { NavbarTop } from './Components/Navbar/NavbarTop'
import { Home } from './Components/Home/Home';
import { FooterComponent } from './Components/FooterComponent/FooterComponent';
import { Route, Router, Routes, useLocation } from 'react-router-dom';
import { HeroCarouselSection } from './Components/HeroCarouselSection/HeroCarouselSection';
import { ContactForm } from './Components/ContactForm/ContactForm';
import { ContactForm2 } from './Components/ContactForm/ContactForm2';
import { CalendlyComponent } from './Components/CalenderComponent/CalendlyComponent';
import { LeanDataGovernance } from './Components/LDG/LeanDataGovernance';
import { Subscribe } from './Components/Subscription/Subscribe';
import { useEffect } from 'react';
import { AiLens } from './Components/AILens/AiLens';
import { DataQalityManagement } from './Components/DataQalityManagement/DataQalityManagement';
import { Form3 } from './Components/ContactForm/Form3';
import { ContactUs } from './Components/ContactForm/ContactUs';
import { PrivacyPolicy } from './Components/PrivacyPolicy/PrivacyPolicy';
import { ValueProposition } from './Components/ValuePropositions/ValueProposition';
import { StrategicAdvisory } from './Components/StrategicAdvisory/StrategicAdvisory';
import { GartnerComponent } from './Components/ExtraComponents/GartnerComponent';



function ScrollToTopOnNavigate() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {




  return (
    <div className="App" style={{overflowX:'hidden'}}>
      
     
      <ScrollToTopOnNavigate />

      <header>
        {/* <Header/> */}
        <NavbarTop />
      </header>
      <main>
        <Routes>
      
          <Route path='/' element={<Home/>}/>
          <Route path = "hero" element = {<HeroCarouselSection/>}/>
          <Route path='contact-us' element = {<ContactForm/>}/>
          <Route path='contact-us2' element = {<ContactForm2/>}/>
          <Route path='calendly' element = {<CalendlyComponent/>}/>
          <Route path='lean-data-governance' element = {<LeanDataGovernance/>}/>
          <Route path='subscribe-now' element = {<Subscribe/>}/>
          <Route path='ai-lens' element = {<AiLens/>}/>
          <Route path='iDQM' element = {<DataQalityManagement/>}/>
          <Route path='test-form' element = {<Form3/>}/>
          <Route path='Contact' element = {<ContactUs/>}/>
          <Route path='privacy-policy' element = {<PrivacyPolicy/>}/>
          <Route path='value-proposition' element = {<ValueProposition/>}/>
          <Route path='strategic-advisory' element = {<StrategicAdvisory/>}/>
          <Route path='gartner' element = {<GartnerComponent/>}/>
        
          
          
        </Routes>

        {/* <Home /> */}
      </main>
      <FooterComponent />

      

    </div>
  );
}

export default App;
