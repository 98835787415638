import React, { useEffect } from 'react';

export const GartnerComponent = () => {

    useEffect(() => {
        // Create and append the widget script
        const script1 = document.createElement('script');
        script1.src = 'https://www.gartner.com/peer-insights/vendor-portal/public/Widget/js/widget.js';
        script1.async = true;
        document.body.appendChild(script1);

        // Initialize the widget when the script has loaded
        script1.onload = () => {
            const script2 = document.createElement('script');
            script2.innerHTML = `
                GartnerPI_Widget({
                    size: "large",
                    theme: "dark",
                    sourcingLink: "",
                    widget_id: "NGQxM2ExZWUtZWFiMy00ZjUzLWI1MjEtZDlhMTBkYmM1YTBh",
                    version: "2",
                    container: document.getElementById("widget-container")  // Use the ID of the container
                });
            `;
            document.body.appendChild(script2);
        };

        // Cleanup function to remove scripts
        return () => {
            document.body.removeChild(script1);
            const script2 = document.querySelector('script[innerHTML*="GartnerPI_Widget"]');
            if (script2) {
                document.body.removeChild(script2);
            }
        };
    }, []);

  return (
    <div id="widget-container" ></div>
  )
}
