import React from 'react'
import EventAvailableIcon from '@mui/icons-material/EventAvailable';

export const BookADemo = (props) => {
  return (
    <div className='py-2 ' 
    style={{
        background:'#0B4A88', 
        color:'#fff' , 
        borderRadius:'15px', 
        paddingLeft:'1.2rem',
        paddingRight:'1.2rem',
        fontSize:'0.8rem'
        }}>
<EventAvailableIcon className='py-1'/>
        {props.name}
    </div>
  )
}
