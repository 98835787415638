import React from 'react'

export const ReadMoreButton = (props) => {
  return (
    <div className='py-2 ' 
    style={{
        background:'#0B4A88', 
        color:'#fff' , 
        borderRadius:'15px', 
        paddingLeft:'1.2rem',
        paddingRight:'1.2rem'
        }}>
        {props.name}
    </div>
  )
}
