import React, { useState } from 'react';
import './LeanDataGovernance.css'
import { Col, Container, Row } from 'react-bootstrap'
import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton'

import { Carousel, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from 'react-router-dom';


export const LeanDataGovernance = () => {

    const [startIndex, setStartIndex] = useState(0); // Start index for displaying content items

    const moveNext = () => {
        if (startIndex < 3) { // Assuming you have 3 items (0-indexed)
            setStartIndex(startIndex + 1);
        }
    };

    const movePrev = () => {
        if (startIndex > 0) {
            setStartIndex(startIndex - 1);
        }
    };

    const renderContent = () => {
        return (
            <>
                <Row className={startIndex === 0 ? '' : 'd-none'}>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }} src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-06.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Data Science</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Generative&Augmented AI.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-07.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Generative
                                    & Augmented AI</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Generative AI creates new content or data using machine
                                learning models, such as text, images, or music. Augmented
                                AI enhances human capabilities by combining AI s strengths
                                with human intelligence for improved decision making and
                                productivity.</p>
                        </div>
                    </Col>

                </Row>
                <Row className={startIndex === 1 ? '' : 'd-none'}>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-07.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Generative
                                    & Augmented AI</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Generative AI creates new content or data using machine
                                learning models, such as text, images, or music. Augmented
                                AI enhances human capabilities by combining AI s strengths
                                with human intelligence for improved decision making and
                                productivity.</p>
                        </div>
                    </Col>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-08.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Business & Data KPIs & KRAs</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                </Row>
                <Row className={startIndex === 2 ? '' : 'd-none'}>
                    <Col >
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-08.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Business & Data KPIs & KRAs</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-09.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Industry Standards, Policies, Procedures</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                </Row>
                <Row className={startIndex === 3 ? '' : 'd-none'}>
                    <Col >
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-09.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Industry Standards, Policies, Procedures</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                    <Col>
                        <div className=" rounded shadow m-3" style={{ background: '#fff', paddingBottom: '2rem' }}>
                            <img className='w-100 rounded' style={{ height: "278px" }}  src={require("../Assets/LDG/Data-Science.jpg")} alt="data-science" loading='lazy'/>
                            <div className='d-flex gap-3 my-3 text-center align-items-center'>
                                <div>
                                    {/* <img style={{ width: '50px' }} className='rounded' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-10.png")} alt="ldg-key-aspects" /> */}
                                </div>
                                <div>
                                    <h6>Security, Risk Compliance & Regulations</h6>
                                </div>
                            </div>
                            <p className='text-start m-3'>Data Science involves extracting insights and knowledge from
                                data using techniques from statistics, computer science, and
                                domain expertise. It encompasses data collection, processing,
                                analysis, and visualization to support decision making and
                                predictive modeling.</p>
                        </div>
                    </Col>
                </Row>
            </>
        );
    };

    return (
        <>
            <section className='ldg-section mt-5'>


                <Container className='ldg-section-1'>
                    <Row>
                        <Col>
                            <div className='ldg-sec-1-content'>
                                <h1>Try Our SaaS Platform and Experience<br />

                                    The Power of Lean Data Governance Today!</h1>

                                <p>Introducing PiLog Lean Data Governance:<br />

                                    The Ultimate Subscription-Based Platform for Empowering Businesses</p>

                                <Link to = '../subscribe-now'><ReadMoreButton name="Subscribe Now" /></Link>
                            </div>
                        </Col>
                    </Row>
                </Container>


            </section>

            <section className='section2-ldg'>
                <Container className='ldg-section-2'>
                    <h2>The Lean Data Governance Concept<br />

                        Promotes Three Significant Pillars.</h2>
                    <Row>
                        <Col>
                            <div className='bg-light rounded p-3 my-2'>
                                <div className='ldg-sec2-card-content shadow'>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-04.png")} alt="" loading='lazy'/>
                                    <h5 >Simple</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='bg-light rounded p-3 my-2'>
                                <div className='ldg-sec2-card-content shadow'>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-05.png")} alt="" loading='lazy'/>
                                    <h5 >Agile</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                        <Col>
                            <div className='bg-light rounded p-3 my-2'>
                                <div className='ldg-sec2-card-content shadow'>
                                    <img className='' style={{ width: '50px' }} src={require("../Assets/LDG/LDG-ICONS/Simple, Agile & Speed-06.png")} alt="" loading='lazy'/>
                                    <h5 >Swift</h5>
                                </div>
                                <div className='ldg-sec2-card-content2'>

                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p> Preconfigured & Augmented Business & Data Content</p>

                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Shift Left User Engagement Models</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Scalable Solutions & Architecture</p>


                                    </div>
                                    <div className='ldg-sec2-card-content-points'>
                                        <i class="fa fa-check" style={{ color: 'red' }} aria-hidden="true"></i>
                                        <p>Enables Multi Domain, Tenant & Language</p>


                                    </div>

                                </div>
                            </div>
                        </Col>
                    </Row>
                    <div className='text-light my-5 pb-5'>
                        <p>This conceptual approach is forged into a powerful product suite that has a complete set of tools to serve the event and data driven digital transformation that contemporary enterprises seek.</p>

                    </div>
                </Container>
            </section>


            <section className='ldg-section-two'>
                <Container>
                    <Row>
                        <Col lg={6}>
                            <div className='key-aspect-section'>
                                <div className='empty-div'></div>
                                <div className='key-aspect-section-heading'>
                                    <h2>Here are some key aspects of
                                        Lean Data Governance:</h2>
                                </div>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-10.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-18.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-11.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-16.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                                <Row className='my-1 p-3'>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-17.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Value & Vision (KPIs & KRAs)</p>
                                        </div>
                                    </Col>
                                    <Col lg={6} className='my-1'>
                                        <div className='d-flex gap-2 align-items-center text-start'>
                                            <img className='key-aspect-images-parts' src={require("../Assets/LDG/LDG-ICONS/LDG-Icon-12.png")} alt="ldg-key-aspects" loading='lazy'/>
                                            <p>Reusable Content, Repository & Dictionary</p>
                                        </div>
                                    </Col>

                                </Row>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className='key-aspect-images'>
                                <img className='image-1' src={require("../Assets/LDG/ldg-key-aspects (1).png")} alt="ldg-key-aspects" loading='lazy'/>
                                {/* <img className='image-gif' src={require("../Assets/LDG/cloud_icon.gif")} alt="ldg-key-aspects-cloud" /> */}

                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section className='ldg-section-3'>
                <Container className='px-5'>
                    <div className='impactful-heading'>
                        <h2>Impactful Business Value Management<br />

                            with Lean Data Governance</h2>
                    </div>
                    <Row>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Impactful Business Value Management with Lean Data Governance-02.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Improved Inventory <br /> Visibility</h6>
                                    <p>Improved Inventory Visibility enhances real time tracking and  management of inventory levels.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Impactful-Icon-02-01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Time & Cost Reduction <br />

                                        & Potential Saving</h6>
                                    <p>Cost avoidance by using <br />

                                        non or slow moving items.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Support Maintenance-01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Support Maintenance <br />

                                        & Operations</h6>
                                    <p>During shutdowns.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-10.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Standardized short & <br />

                                        long descriptions</h6>
                                    <p>to improve searching and <br />

                                        easy identification.</p>
                                </div>

                            </div>
                        </Col>

                    </Row>

                    <Row>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Zero down time during maintenance-01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Zero Down Time During <br /> Maintenance</h6>
                                    <p>as spares availability <br /> would be HIGH.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/category-01-01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Supplier to Material & <br />Product Category Linkage:</h6>
                                    <p>Easy approach to suppliers in case

                                        the materials found but the owner of

                                        the spare refuse to release.</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Extended Partnership -01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Extended Partnership</h6>
                                    <p>Build the foundation & strengthen

                                        the relationship among companies</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={12} sm={10}>
                            <div className='impact-business-topics'>
                                <div className='impact-image shadow'>
                                    <img className='w-100' src={require("../Assets/LDG/LDG-ICONS/Domestic Vs. Import-01.png")} alt="ldg-key-aspects" loading='lazy'/>

                                </div>
                                <div className='impact-business-content'>
                                    <h6>Domestic Vs. Import</h6>
                                    <p>Provide accurate information for

                                        local content & decision making</p>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>

            {/* <section className='ldg-last-section'>
                <div className='ldg-idxp-heading'>
                    <h2>Lean Data Governance Powered by PiLog iDXP (Data Exchange Platform)</h2>
                </div>
                <Container>
                    <Row>
                        <Col lg={2} md={4} xs={6} sm={6}>
                            <div className='impact-business-topics'>
                                <div className=''>
                                    <img className='w-50' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-06.png")} alt="ldg-key-aspects" />

                                </div>
                                <div className='impact-business-content'>

                                    <p>Data Science</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={2} md={4} xs={6} sm={6}>
                            <div className='impact-business-topics'>
                                <div className=''>
                                    <img className='w-50' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-07.png")} alt="ldg-key-aspects" />

                                </div>
                                <div className='impact-business-content'>

                                    <p>Generative & Augmented AI</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={2} md={4} xs={6} sm={6}>
                            <div className='impact-business-topics'>
                                <div className=''>
                                    <img className='w-50' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-08.png")} alt="ldg-key-aspects" />

                                </div>
                                <div className='impact-business-content'>

                                    <p>Business & Data KPIs & KRAs</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={2} md={4} xs={6} sm={6}>
                            <div className='impact-business-topics'>
                                <div className=''>
                                    <img className='w-50' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-09.png")} alt="ldg-key-aspects" />

                                </div>
                                <div className='impact-business-content'>

                                    <p>Industry Standards, Policies, Procedures</p>
                                </div>

                            </div>
                        </Col>
                        <Col lg={2} md={4} xs={6} sm={6}>
                            <div className='impact-business-topics'>
                                <div className=''>
                                    <img className='w-50' src={require("../Assets/LDG/LDG-ICONS/LDG-ICONS-10.png")} alt="ldg-key-aspects" />

                                </div>
                                <div className='impact-business-content'>

                                    <p>Security, Risk Compliance & Regulations</p>
                                </div>

                            </div>
                        </Col>

                    </Row>
                </Container>
            </section> */}

            <section>

                <Container>
                    <div className='p-4'>

                        {renderContent()}
                        <Row className="mt-3">
                            <Col className="d-flex justify-content-between" style={{ marginTop: '-19.2rem' }} >
                                <ArrowBackIcon variant="primary" onClick={movePrev} disabled={startIndex === 0} style={{ height: '2rem', width: '2rem', marginLeft: '-3rem', background:'#0b4a99', borderRadius:'50%', color:'#fff', fontSize:'16px' }}>
                                    Previous
                                </ArrowBackIcon>
                                <ArrowForwardIcon variant="primary" onClick={moveNext} disabled={startIndex === 2} style={{ height: '2rem', width: '2rem', marginRight: '-3rem', background:'#0b4a99', borderRadius:'50%', color:'#fff' }}>
                                    Next
                                </ArrowForwardIcon>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>

            <section className='contemporary-trend-LDG'>
                <Container>
                    <Row>
                        <Col className='contemporary-trend-LDG-content'>
                            <div >
                                <h3>The contemporary trend is to be AI Ready,

                                    but are we Data Ready and AI Ready?</h3>
                                <p>Lean Data Governance is the bedrock for eliminating waste from business
                                    processes and supporting decision making at its best, whether supported

                                    by humans, Artificial Intelligence or both.</p>

                            </div>
                        </Col>
                        <Col>
                            <div>
                                <img className='w-75' src={require("../Assets/LDG/Imad-Sir-Pic.jpg")} alt="ldg-key-aspects" loading='lazy'/>

                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}
