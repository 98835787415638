import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import './ContactUs.css'
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { ReadMoreButton } from '../ExtraComponents/ReadMoreButton';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Link } from 'react-router-dom';
import { ValidForm } from './ValidForm';

export const ContactUs = () => {
    return (
        <div>
            <section className='contact-us-section1'>
                <Container>
                    <Row>
                        <Col lg={8}>
                            <div className='contact-us-heading'>
                                <h1 >Let's get in touch</h1>
                                <p>We would be delighted to serve you with our services,

                                    just use the form below or choose<br /> the services you are

                                    interested in and we will be in touch with you.</p>
                            </div>
                        </Col>

                    </Row>
                </Container>
            </section>
            <section>
                <Container>
                    <Row className='d-flex justify-content-center'>
                        {/* <Col lg={5} md={6} sm={12} className=''>
                            <div>
                                <div className='contact-us-address'>
                                    <LocationOnIcon className='icons' />
                                    <p> Hyderabad,
                                        Telangana 500 008</p>
                                </div>
                                <div className='contact-us-address'>
                                    <EmailIcon className='icons' />
                                    <p>promotions@piloggroup.com</p>
                                </div>
                                <div className='contact-us-address'>
                                    <LocalPhoneIcon className='icons' />
                                    <p>+91 40 660 17 777/788</p>
                                </div>
                              

                            </div>

                        </Col> */}

                        <Col lg={8} md={6} sm={12} className='contact-us-form'>
                            <div className='contactus-form shadow rounded'>
                                <div className='contactus-form-heading'>
                                    <h3>Get in touch</h3>

                                </div>
                                {/* <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <input className='contact-inputs' type="text" name="name" id="name" placeholder='Enter your name' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <input className='contact-inputs' type="email" name="email" id="email" placeholder='Enter your Email' />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <input className='contact-inputs' type="text" name="company" id="company" placeholder='Enter your Company' />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <select name="" id="" className='contact-inputs'>
                                                <option value="Select your country">Select your country</option>
                                                <option value="one">one</option>
                                                <option value="one">two</option>
                                                <option value="one">three</option>
                                                <option value="one">four</option>
                                            </select>
                                            
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <input className='contact-inputs' type="number" name="phone" id="phone" placeholder='Enter your Phone Number' />
                                        </div>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <div className=''>
                                            <textarea className='contact-inputs' type="text" name="message" id="message" placeholder='Enter your Message'></textarea>
                                            
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <ReadMoreButton name="Submit" type="submit" />
                                    </Col>
                                </Row> */}

<ValidForm/>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}
