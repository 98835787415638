// import React, { useEffect, useRef, useState } from "react";
// import './HeroCarouselSection.css'

// import image1 from "../Assets/Home/Home-Page-Banner-Final.jpg";
// import image2 from "../Assets/Home/Home-Page-Banner2-Final.jpg";
// import image3 from "../Assets/Home/Home-Page-Banner3-Final.jpg";
// import image4 from "../Assets/Home/Home-Page-Banner4-Final4.jpg";

// export const HeroCarouselSection = () => {
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   const images = [image1, image2, image3, image4];

//   const handleThumbnailClick = (index) => {
//     setSelectedImageIndex(index);
//   };

//   return (
//     <section className="hero-carousel mt-5">
//       <div className="hero-carousel__background" style={{ backgroundImage: `url(${images[selectedImageIndex]})` }}>
//         <div className="hero-carousel__thumbnails">
//           {images.map((image, index) => (
//             <img
//               key={index}
//               src={image}
//               alt={`Thumbnail ${index + 1}`}
//               className={`thumbnail ${selectedImageIndex === index ? "selected" : ""}`}
//               onClick={() => handleThumbnailClick(index)}
//             />
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// };


import React, { useEffect, useRef, useState } from "react";
import './HeroCarouselSection.css';

import image1 from "../Assets/Home/Home-Page-Banner-Final.jpg";
import image2 from "../Assets/Home/Home-Page-Banner2-Final.jpg";
import image3 from "../Assets/Home/Home-Page-Banner3-Final.jpg";
import image4 from "../Assets/Home/Home-Page-Banner4-Final4.jpg";

export const HeroCarouselSection = () => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const images = [image1, image2, image3, image4];
  const intervalRef = useRef(null);

  const handleThumbnailClick = (index) => {
    setSelectedImageIndex(index);
    resetAutoChange();
  };

  const resetAutoChange = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = setInterval(() => {
      setSelectedImageIndex(prevIndex => (prevIndex + 1) % images.length);
    }, 5000); // Change image every 5 seconds
  };

  useEffect(() => {
    resetAutoChange();
    return () => clearInterval(intervalRef.current); // Cleanup on component unmount
  }, []);

  return (
    <section className="hero-carousel mt-5">
      <div className="hero-carousel__content">
        <div className="hero-carousel__background" style={{ backgroundImage: `url(${images[selectedImageIndex]})` }}></div>
        <div className="hero-carousel__thumbnails">
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              className={`thumbnail ${selectedImageIndex === index ? "selected" : ""}`}
              onClick={() => handleThumbnailClick(index)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};


