import React from 'react'
import './ContactForm2.css'
export const ContactForm2 = () => {
  return (
    <div>
        <section class="contact-page-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="contact-form-info">
            <div class="contact-form-info-item">
              <div class="contact-form-info-icon">
              <i class="fa fa-map-marker" aria-hidden="true"></i>
              </div>
              <div class="contact-form-info-text">
                <h2>address</h2>
                <span>3rd Floor, Unit-1, MJR Magnifique,</span> 
                <span> Hyderabad, Telangana - India</span> 
              </div>
            </div>            
          </div>          
        </div>          
        <div class="col-md-4">
          <div class="contact-form-info">
            <div class="contact-form-info-item">
              <div class="contact-form-info-icon">
              <i class="fa fa-envelope" aria-hidden="true"></i>
              </div>
              <div class="contact-form-info-text">
                <h2>E-mail</h2>
                <span>info@piloggroup.org</span> 
                <span>yourmail@gmail.com</span>
              </div>
            </div>            
          </div>                
        </div>                
        <div class="col-md-4">
          <div class="contact-form-info">
            <div class="contact-form-info-item">
              <div class="contact-form-info-icon">
              <i class="fa fa-clock-o" aria-hidden="true"></i>
              </div>
              <div class="contact-form-info-text">
                <h2>office time</h2>
                <span>Mon - Fri  9:00 am - 6:30 pm</span>
                <span>Sat - Sun  Holiday</span>
              </div>
            </div>            
          </div>          
        </div>          
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="contact-page-form" method="post">
            <h2>Get in Touch</h2> 
            <form action="contact-mail.php" method="post">
              <div class="row">
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" placeholder="Your Name" name="name"/>
                </div>
              </div>  
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="email" placeholder="E-mail" name="email" required/>
                </div>
              </div>                              
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" placeholder="Phone Number" name="phone"/>
                </div>
              </div>  
              <div class="col-md-6 col-sm-6 col-xs-12">
                <div class="single-input-field">
                  <input type="text" placeholder="Subject" name="subject"/>
                </div>
              </div>                
              <div class="col-md-12 message-input">
                <div class="single-input-field">
                  <textarea  placeholder="Write Your Message" name="message"></textarea>
                </div>
              </div>                                                
              <div class="single-input-fieldsbtn">
                <input type="submit" value="Send Now"/>
              </div>                          
            </div>
            </form>   
          </div>      
        </div>
        <div class="col-md-4">        
          <div class="contact-page-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7613.566689766396!2d78.38213!3d17.422182!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb96eb3a70fae7%3A0xe802be4593e9fdc0!2sPiLog%20Group!5e0!3m2!1sen!2sus!4v1718776650046!5m2!1sen!2sus" width="100%" height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>          
        </div>        
      </div>
    </div>
  </section>
    </div>
  )
}

