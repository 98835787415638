import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckIcon from '@mui/icons-material/Check';
import { Button } from 'react-bootstrap';
import CloseIcon from '@mui/icons-material/Close';
import { TableBar } from '@mui/icons-material';
import './Subscribe.css'

function createData(name, content, subheading, history) {
    return {
        name,
        content,
        subheading,
        history
    };
}

const rows = [
    createData('Type of Subscription', "", "Details", [
        { subheading: 'Type of Subscription', icon1: 'Annually', icon2:  'Annually', icon3:  'Annually'},
        { subheading: 'No. of Users', icon1: "Up to 5", icon2: "Up to 20", icon3: "Above 20" },
        { subheading: 'No. of Item Requests/per year', icon1: "2,000", icon2: "5,000", icon3: "Above 5000" },
        
    ]),
    createData('Domain Specific Features', "", "Details", [
        { subheading: 'Product', icon1:"Smart Search", icon2:  <CheckIcon />, icon3:  <CheckIcon /> },
        

    ]),
    createData('Service', "", "Details", [
        { subheading: 'Smart Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Parametric Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Category & UNSPSC Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />, icon4: <CheckIcon /> },
        { subheading: 'Dictionary & Classification Search',     icon1: <CheckIcon />,icon2:'Create', icon3: <CheckIcon /> },
        { subheading: 'Extend', icon1: <CloseIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Block', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Unblock', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'MSS Create',icon1: <CloseIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'MSS Change',icon1: <CloseIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        

    ]),
    createData('Vendor', "", "Details", [
        { subheading: 'Smart Search [Fuzzy & Free Text]', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Parametric Search', icon1: "Create", icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Extend', icon1:<CloseIcon/>, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Block', icon1:<CheckIcon />, icon2:  <CheckIcon />, icon3: <CheckIcon />},
        { subheading: "Unblock", icon1:<CheckIcon />, icon2:<CheckIcon />, icon3:<CheckIcon />},
        { subheading: "Onboarding Process", icon1: <CloseIcon/> , icon2: <CloseIcon/> , icon3: <CheckIcon /> },
      
    ]),
    createData('Customer', "", "Details", [
        { subheading: 'Smart Search [Fuzzy & Free Text]', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Parametric Search', icon1: <CheckIcon />, icon2: "Create", icon3: <CheckIcon /> },
        { subheading: 'Extend', icon1: <CloseIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Block', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Unblock', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Onboarding Process', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        
    ]),
    createData('Business Partner', "", "Details", [
        { subheading: 'Smart Search [Fuzzy & Free Text]', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Parametric Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Create', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: "Extend" },
        { subheading: 'Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon />},
        { subheading: 'Block', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Unblock', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Onboarding Process', icon1: <CloseIcon/>, icon2: <CloseIcon />, icon3: <CheckIcon /> },

    ]),
    createData('Asset', "", "Details", [
        { subheading: 'Smart Search [Fuzzy, Reference, Classification & Free Text]', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Parametric Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Category & UNSPSC Search', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Dictionary & Classification Search',icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Equipment Create', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Equipment Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Functional Location Create', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'Functional Location Change', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'eBOM Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'fBOM Create', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'fBOM Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Task List Create', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Task List Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Maintenance Plan Create', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Maintenance Plan Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Work Center Create', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Work Center Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Measuring Point Create', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
        { subheading: 'Measuring Point Change', icon1: <CloseIcon />, icon2: <CloseIcon/>, icon3: <CheckIcon /> },
       

    ]),
    createData('Fixed Asset', "", "Details", [
        { subheading: 'Create', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
        { subheading: 'Change', icon1: <CloseIcon /> , icon2: <CloseIcon /> , icon3: <CheckIcon />  },
        

    ]),
    createData('Incident', "", "Details", [
        { subheading: 'Create', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
        { subheading: 'Change', icon1: <CloseIcon /> , icon2: <CloseIcon /> , icon3: <CheckIcon />  },
        

    ]),
    createData('Leave Master', "", "Details", [
        { subheading: 'Create', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
        { subheading: 'Change', icon1: <CloseIcon /> , icon2: <CloseIcon /> , icon3: <CheckIcon />  },
        

    ]),
    createData('Exportability', "", "Details", [
        { subheading: 'XLSX', icon1:<CheckIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
        { subheading: 'JSON', icon1: <CloseIcon /> , icon2: <CloseIcon /> , icon3: <CheckIcon />  },
        { subheading: 'CSV', icon1: <CloseIcon /> , icon2: <CloseIcon /> , icon3: <CheckIcon />  },
        

    ]),
    createData('Multi Lingual Content', "", "Details", [
        { subheading: 'Multi Lingual Content', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
        

    ]),
    createData('Additional Language [Non-English]', "", "Details", [
        { subheading: 'Additional Language [Non-English]', icon1:<CloseIcon/> , icon2:<CloseIcon/>, icon3:<CheckIcon /> },
        

    ]),
    createData('Workflows included', "", "Details", [
        { subheading: 'Workflows included', icon1:<CheckIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
        

    ]),
    createData('Extension', "", "Details", [
        { subheading: 'Extension', icon1:<CloseIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Customised WorkFlow', "", "Details", [
        { subheading: 'Customised WorkFlow', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Business Rules / Derivations limitation', "", "Details", [
        { subheading: 'Business Rules / Derivations limitation', icon1:"Up to 5" , icon2:"Up to 10", icon3:"Above 10" },
    ]),
    createData('Notifications Management (within the system)', "", "Details", [
        { subheading: 'Notifications Management (within the system)', icon1:<CheckIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Notifications Management (Email)', "", "Details", [
        { subheading: 'Notifications Management (Email)', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),

    createData('Add-On Data Services APIs', "", "Details", [
        { subheading: 'PPR Search [10 Million Records Repository]', icon1: <CheckIcon />, icon2: <CheckIcon />, icon3: <CheckIcon /> },
        { subheading: 'UNSPSC/Categorization Mapping', icon1: <CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
        { subheading: 'Spend Analysis', icon1:<CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
        {subheading: 'Class Allocation', icon1:<CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
        {subheading: 'Classification/Char Values Extraction', icon1:<CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
        {subheading: 'Reference Data Extraction', icon1:<CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
        {subheading: 'Data Cleansing', icon1:<CloseIcon />, icon2: <CloseIcon />, icon3: <CloseIcon />},
    ]),

    createData('UI Type - HTML', "", "Details", [
        { subheading: 'UI Type - HTML', icon1:<CheckIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
    ]),
    createData('UI Type - Mobile', "", "Details", [
        { subheading: 'Search', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
        { subheading: 'Approve', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),

    createData('Replication to ERP for all Extended Objects', "", "Details", [
        { subheading: 'Replication to ERP for all Extended Objects', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Replication to Multiple ERPs', "", "Details", [
        { subheading: 'Replication to Multiple ERPs', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Domains Supported', "", "Details", [
        { subheading: 'Domains Supported', icon1:"Only 1" , icon2:"Up to 3", icon3:"Above 3" },
    ]),
    createData('Item/Record Types', "", "Details", [
        { subheading: 'Item/Record Types', icon1:"Up to 3" , icon2:"Up to 4", icon3:"Above 4" },
    ]),
    createData('Plants Included', "", "Details", [
        { subheading: 'Plants Included', icon1:"Up to 1" , icon2:"Up to 4", icon3:"Above 4" },
    ]),
    createData('Roles Included', "", "Details", [
        { subheading: 'Roles Included', icon1:"Up to 2" , icon2:"Up to 3", icon3:"Above 3" },
    ]),
    createData('User Management UI', "", "Details", [
        { subheading: 'User Management UI', icon1:<CloseIcon/> , icon2:<CheckIcon />, icon3:<CheckIcon /> },
    ]),
    createData('Generic Reports and InfoGraphics', "", "Details", [
        { subheading: 'Generic Reports and InfoGraphics', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),
    createData('ERP Base Tables', "", "Details", [
        { subheading: 'ERP Base Tables', icon1:<CloseIcon/> , icon2:<CloseIcon />, icon3:<CheckIcon /> },
    ]),


    

    // Add other rows as needed
];

function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    return (
        <React.Fragment>
            <TableRow className='table-body'>
                <TableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <h5>{row.name}</h5>
                    <p>{row.content}</p>
                </TableCell>
                {/* Add other main row cells if needed */}
            </TableRow>
            <TableRow >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6} >
                    <Collapse in={open} timeout="auto" unmountOnExit >
                        <Box sx={{ margin: 1 }} className='border-bottom border-dark'>
                            <Typography variant="h6" gutterBottom component="div" >

                            </Typography>
                            <Table size="small" aria-label="purchases">
                                <TableBody >
                                    {row.history && row.history.map((historyRow, index) => (
                                        <TableRow key={index}>
                                            <TableCell className='sub-head border-bottom border-gray'>{historyRow.subheading}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon1}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon2}</TableCell>
                                            <TableCell className='tickmark' align="right">{historyRow.icon3}</TableCell>
                                            {/* <TableCell className='tickmark' align="right">{historyRow.icon4}</TableCell> */}

                                            {/* Add other nested row cells if needed */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        name: PropTypes.string.isRequired,
        content: PropTypes.string.isRequired,
        subheading: PropTypes.string.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                subheading: PropTypes.string.isRequired,
                icon: PropTypes.element.isRequired,
            }),
        ).isRequired,
    }).isRequired,
};

export default function TableAcordian() {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead className='sticky-header' >
                    <TableRow className='table-row'>
                        <TableCell />
                        <th className=" text-start px-3"><p>Check out our feature highlights that turn data into decisions!</p></th>
                        <th className="head-2 shadow p-3 mb-5  rounded"><h4>Basic</h4>
                        <p>Ideal for small operations</p>
                            <p>
                                <s><b style={{color:'red', marginLeft:10}}>$299</b></s>
                                <b>$149</b>
                                User/Month</p>
                            <Button className="px-4 bg-primary text-light">Buy Now</Button></th>
                        <th className="head-2 py-2 shadow rounded-3"><h4>Professional</h4>
                        <h6>Perfect for medium operations</h6>
                        <p>
                        <s><b style={{color:'red', marginLeft:10}}>$399</b></s>
                                <b>$269</b>
                                User/Month</p>
                                <Button className="px-4 bg-primary text-light">Buy Now</Button></th>
                        <th className="head-2 py-2 shadow rounded-3"><h4>Enterprise</h4>
                        <h6>Ideal for larger operations</h6>

                            <p>Request for Price</p>
                            <Button className="px-4 bg-primary text-light">Buy Now</Button></th>

                    </TableRow>
                </TableHead>
                <TableBody>
                   
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
