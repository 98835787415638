import React, { useState } from 'react'

export const Form3 = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch('./api/sendmail.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });
        if (response.ok) {
          alert('Email sent successfully!');
        } else {
          alert('Failed to send email.');
        }
      };

  return (
    <div style={{marginTop:'10rem'}}>
        <form onSubmit={handleSubmit}>
      <input type="text" name="name" placeholder="Name" value={formData.name} onChange={handleChange} />
      <input type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
      <input type="text" name="mobile" placeholder="Mobile" value={formData.mobile} onChange={handleChange} />
      <textarea name="message" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
      <button type="submit">Send</button>
    </form>
    </div>
  )
}
